module.exports = {
  /**
   * 请求封装
   * @param {Object} options
   */
  ajax: function(options) {
    const url = options.url + '?' + this.formatParams(options.data)
    const img = new Image()
    let timer = null
    img.src = url
    img.onload = function() {
      clearTimeout(timer)
      options.success && options.success()
    }
    img.onerror = function() {
      clearTimeout(timer)
      options.error && options.error()
    }
    if (options.time) {
      timer = setTimeout(function() {
        options.timeout && options.timeout()
      }, options.time)
    }
  },

  xhr(type, url, data, success, failed) {
    // 创建ajax对象
    var xhr = null
    if (window.XMLHttpRequest) {
      // 非IE浏览器
      xhr = new window.XMLHttpRequest()
    } else {
      // IE浏览器
      xhr = new window.ActiveXObject('Microsoft.XMLHTTP')
    }

    // 用于清除缓存
    var random = Math.random()

    if (typeof data === 'object') {
      var str = ''
      for (var key in data) {
        str += key + '=' + data[key] + '&'
      }
      data = str.replace(/&$/, '')
    }

    if (type.toUpperCase() === 'GET') {
      if (data) {
        xhr.open('GET', url + '?' + data, true)
      } else {
        xhr.open('GET', url + '?t=' + random, true)
      }
      xhr.send()
    } else if (type.toUpperCase() === 'POST') {
      xhr.open('POST', url, true)
      // 如果需要像 html 表单那样 POST 数据，请使用 setRequestHeader() 来添加 http 头。
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
      xhr.send(data)
    }

    // 处理返回数据
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        /*
         ** Http状态码
         ** 1xx ：信息展示
         ** 2xx ：成功
         ** 3xx ：重定向
         ** 4xx : 客户端错误
         ** 5xx ：服务器端错误
         */
        if (xhr.status === 200) {
          try {
            var data = JSON.parse(xhr.responseText)
            success(data)
          } catch (error) {
            success(xhr.responseText)
          }
        } else {
          if (failed) {
            failed(xhr.status)
          }
        }
      }
    }
  },

  /**
   * 格式化参数
   * @param {Object} data
   */
  formatParams: function(data) {
    if (!data) return
    const arr = []
    for (const name in data) {
      arr.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]))
    }
    return arr.join('&')
  },
  /**
   * 在指定链接上查询指定参数
   * @param {String} name 需要查询的key
   * @param {String} path 链接
   */
  getParam: function(name, path = window.location.href) {
    const result =
      decodeURIComponent(
        (new RegExp('[?|&]' + name + '=([^&;]+?)(&|#|;|$)').exec(path) || [
          undefined,
          ''
        ])[1].replace(/\+/g, '%20')
      ) || null
    return result ? result.split('/')[0] : ''
  },
  /**
   * 复制到剪贴板
   * @param {String} text 需要复制到剪贴板的内容
   */
  copyBoard: function(text) {
    const textArea = document.createElement('textarea')
    textArea.style.cssText = 'position:fixed;top:0;left:0;width:2em;height:2em;padding:0;border:none;outline:none;boxShadow:none;background:transparent;'
    textArea.value = text
    document.body.appendChild(textArea)
    const isReadOnly = textArea.hasAttribute('readonly')
    if (!isReadOnly) {
      textArea.setAttribute('readonly', '')
    }
    textArea.select()
    textArea.setSelectionRange(0, textArea.value.length)
    if (!isReadOnly) {
      textArea.removeAttribute('readonly')
    }
    try {
      if (document.execCommand('copy')) {
        console.log('复制成功')
      } else {
        console.log('您的浏览器不支持自动复制，请长按复制')
      }
    } catch (err) {
      console.log('不能使用这种方法复制内容')
    }
    document.body.removeChild(textArea)
  },
  getCache: function(key) {
    return this.isSupportedLocalStorage ? window.localStorage.getItem(key) : ''
  },
  setCache: function(key, value) {
    if (this.isSupportedLocalStorage) window.localStorage.setItem(key, value)
  },
  getConfig: function(name) {
    if (window.CFG && window.CFG[name]) {
      return window.CFG[name]
    }
    return null
  },
  isSupportedLocalStorage: (function() {
    let _isSupported
    const key = '_ls_test'
    try {
      window.localStorage.setItem(key, 1)
      window.localStorage.removeItem(key)
      _isSupported = true
    } catch (e) {
      _isSupported = false
      // https://stackoverflow.com/questions/21159301/quotaexceedederror-dom-exception-22-an-attempt-was-made-to-add-something-to-st
    }
    return _isSupported
  })(),
  noop: function() { },
  cloneObj: function(oldObj) {
    var self = this
    if (typeof (oldObj) !== 'object') return oldObj
    if (oldObj == null) return oldObj
    var newObj = {}
    for (var i in oldObj) {
      newObj[i] = self.cloneObj(oldObj[i])
    }
    return newObj
  },
  extendObj: function() {
    var args = arguments
    if (args.length < 2) return
    var temp = this.cloneObj(args[0])
    for (var n = 1; n < args.length; n++) {
      for (var i in args[n]) {
        temp[i] = args[n][i]
      }
    }
    return temp
  },
  typeCheck: function(obj) {
    const type = Object.prototype.toString.call(obj)
    return type.match(/[A-Z]{1}[a-z]*/)[0].toLowerCase()
  }
}
