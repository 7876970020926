import { sendSubTypeLog } from "./dom_monitor";

/**
 * 页面停留时长监控
 */
const STAY_DURATION = 5000;
const STAY_DURATION_SUBTYPE = 19;

const startDurationMonitor = () => {
  let timer = setTimeout(() => {
    sendSubTypeLog(STAY_DURATION_SUBTYPE, "", "", {
      ignoreAdvertKey: true,
      LandingSingle: 1
    });
    clearTimeout(timer);
    timer = null;
  }, STAY_DURATION);
};

const initDurationMonitor = () => {
  if (document.readyState === "complete") {
    startDurationMonitor();
  } else {
    document.addEventListener("readystatechange", () => {
      if (document.readyState === "complete") {
        startDurationMonitor();
      }
    });
  }
};

export { initDurationMonitor };
