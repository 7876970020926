import utils from './utils'

(function(w) {
  w.CFG = w.CFG || {
    appId: 1, // 默认设置一个值
    slotId: 1 // 默认设置一个值
  }

  let relUrl = document.URL.split('//')[1]
  let client = 'advert'
  const isJimu = relUrl.substr(0, 4) === 'jimu'
  const isMofang = relUrl.indexOf('mofang') !== -1
  const isBQ = window.location.href.indexOf('/item/detail2') !== -1 || window.location.href.indexOf('/item/detail3') !== -1

  if (isJimu) {
    relUrl = utils.getParam('id')
    client = 'jimu'
  }
  if (isMofang) {
    relUrl = utils.getParam('id')
    client = 'mofang'
  }
  if (isBQ) {
    relUrl = utils.getParam('pid')
    client = 'baiqi'
  }

  // 把jimu的和外部广告主的区分开
  w.HUNTER_CONFIG = w.HUNTER_CONFIG  || {}
  w.HUNTER_CONFIG.client = client
  w.HUNTER_CONFIG.pageId = relUrl

  require('@tuia/hunter-sdk')
})(window)
