const utils = require('./utils')
const CONST = require('./CONST')
const {backupHosts, defaultHost} = CONST

/**
 * 获取可用的埋点域名
 * 如果传入域名都被使用过说明所有埋点域名都挂了（极小概率）
 * @param {String} host 当前使用的埋点host
 * @param {String} status 当前请求可用域名时的状态，如果是fallback说明非首次请求，当host为defaultHost时直接返回false
 */
const getEffectiveHost = (host, status = '') => {
  // 取出内存/本地存储中缓存好的host
  const currentHost = getHostFromStorage();
  // 如果缓存中不存在host说明当前设备是第一次发送埋点，直接取backupHosts第一个元素返回
  if (!currentHost) {
    return backupHosts[0];
  }
  // 如果缓存host和入参host不一致则直接用缓存中的，该情况出现在当前设备落地页多次访问发送埋点且无域名挂了的情况
  // 大部分是该情况
  if (currentHost && currentHost !== host) {
    return currentHost;
  }
  // 如果是fallback情况而且host已经是defaultHost则直接返回false
  if (status === 'fallback' && host === defaultHost) {
    return 'failed';
  }
  // 如果缓存host和入参host一致或入参host存在于backupHosts列表中
  // 说明backupHosts中已经有域名挂了，此时在backupHosts中取未使用过的host返回
  if (backupHosts[backupHosts.length - 1] !== host) {
    return backupHosts[backupHosts.indexOf(host) + 1];
  }
  // 如果backupHosts中所有的域名都用过了说明域名都阵亡，返回defaultHost最后挣扎一下
  if (host !== defaultHost) {
    return defaultHost;
  }
  // 都尝试过了就放弃等待修复
  return 'failed';
};

/**
 * 将域名写入到缓存中
 * @param {String} host 待写入的域名 
 */
const writeHostToStorage = host => {
  window.TATrackHost = host;
  window.localStorage && window.localStorage.setItem("TATrackHost", host);
};

/**
 * 获取缓存中的埋点域名
 */
const getHostFromStorage = () => {
  return (
    window.TATrackHost ||
    (window.localStorage && window.localStorage.getItem("TATrackHost"))
  );
};

const advert = {
  /**
   * 业务数据上传封装 用于曝光 & 转化 & 自定义埋点   数据上报入口一
   * 最终数据结构为{type: xxx, json: xxx}
   * @param {Object} typeData 数据来自CONST.js 包含url与type
   * @param {Object} json 需要上传的数据
   * @param {Function} successCB 成功回调
   * @param {Function} errorCB 错误回调
   * @param {Function} timeoutCB 超时回调
   */
  dataReport({ typeData, json, successCB = utils.noop, errorCB = utils.noop, timeoutCB = utils.noop }) {
    // 没有oId不发送曝光/转化
    if (!json.oId) {
      successCB()
      return
    }
    const data = { }
    if (typeData.type) data.type = typeData.type
    data._t = new Date().getTime()
    try {
      data.json = JSON.stringify(json)
    } catch (e) { }
    const host = getEffectiveHost(typeData.domain, typeData.status)
    // 将当前使用的host写入到缓存中
    writeHostToStorage(host);
    // 当所有域名都失效时直接触发errorCB
    if (host === 'failed') {
      console.log(`all host failed`);
      errorCB();
      return;
    }
    const option = arguments[0];
    utils.ajax({
      url: `${host}${typeData.api}`,
      data,
      success() {
        successCB();
      },
      error: () => {
        // 在type=7的落地页曝光埋点中做埋点域名的检测
        if (data.type === 7) {
          console.log(`${host}埋点发送失败`);
          option.typeData.domain = host;
          option.typeData.status = 'fallback'
          this.dataReport(option);
          return;
        }
        errorCB();
      },
      timeout() {
        timeoutCB();
      }
    });
  },
  /**
   * 将关键数据存入localstorage
   * @param {Object} json index中的主要数据
   */
  setCacheData(json) {
    if (json.oId) utils.setCache(CONST.CACHE_NAME + 'oId', json.oId)
    if (json.cid) utils.setCache(CONST.CACHE_NAME + 'cid', json.cid)
    if (json.tuiaId) utils.setCache(CONST.CACHE_NAME + 'tuiaId', json.tuiaId)
  },
  /**
   * 从缓存中取出关键字段
   * @param {String} str 想要在缓存中查询的字段名
   */
  getCacheData(str) {
    const temp = str.replace('a_', '')
    return utils.getCache(CONST.CACHE_NAME + temp)
  },
  /**
   * 查询参数唯一入口
   * @param {String} str 需要查询的参数
   */
  getPathParam(str) {
    try {
      return utils.getParam(str) || utils.getParam(str, window.top.location.href) || advert.getCacheData(str) || -1
    } catch (error) {
      return -1
    }
  },
  /**
   * 业务逻辑处理：确保转化数据细分
   * @param {Object} obj
   */
  extraDataHandler(obj) {
    const defaultData = {
      isCopy: 0,
      pageType: 0
    }
    const type = utils.typeCheck(obj)
    if (type === 'null' || type === 'undefined') return defaultData
    else if (type === 'object') {
      if (!obj.isCopy) obj.isCopy = 0
      if (!obj.pageType) obj.pageType = 0
      return obj
    } else {
      defaultData.unkonwData = (obj.toString && obj.toString()) || ''
      return defaultData
    }
  },
  /**
   * 绑定转化事件(此功能不再维护，保留功能即可)
   * @param {Object} countLog 转化对象
   */
  countLogEventBind(countLog) {
    const that = advert
    let startTime = new Date().getTime()
    document.addEventListener('touchstart', () => {
      // 记录点击时间点
      startTime = new Date().getTime()
    }, false)
    document.addEventListener('touchend', (e) => {
      // 正常情况e.path为一个数组，表示从最上层开始的被点击的元素
      const path = e.path || [e.target, e.target.parentNode]
      const len = path.length
      // 监听每一个被点击的元素
      for (let i = 0; i < len; i++) {
        const elm = path[i]
        // 当被点击元素含有属性 data-setting-click时 发生转化
        if (elm.dataset && elm.dataset.settingClick) {
          let cb = utils.noop
          if (elm.tagName === 'A' && elm.getAttribute('href') && elm.getAttribute('href').indexOf('javascript:') === -1) {
            cb = () => {
              window.location.href = elm.getAttribute('href')
            }
          }
          countLog.init(cb, {
            location: elm.dataset.settingClick
          })
        }
        // 当被点击元素含有属性 data-setting-copy时  复制指定的值 如：data-setting-copy = ${a_oId} 则复制订单号
        if (elm.dataset && elm.dataset.settingCopy) {
          var text = elm.dataset.settingCopy
          var rgxVar = text.match(/\$\{(.+?)\}/g)
          if (rgxVar && rgxVar.length) {
            for (var index = 0; index < rgxVar.length; index++) {
              var name = rgxVar[index].replace('${', '')
              name = name.replace('}', '')
              text = text.replace(rgxVar[index], that.getPathParam(name))
            }
          }
          utils.copyBoard(text)
        }
        // 当被点击元素含有属性 data-setting-press时 若持续按住dom超过设定时间 则发生转化
        if (elm.dataset && elm.dataset.settingPress) {
          const endTime = new Date().getTime()
          if (endTime - startTime > CONST.PRESSTIME) {
            countLog.init(utils.noop, {
              location: elm.dataset.settingPress
            })
          }
        }
      }
    })
  },
  /**
   * 异步请求判断是否转化复制订单号黑名单
   */
  isCoversionBlacklist(a_appId, successCB, errorCB) {
    utils.xhr(
      'GET',
      'https://activity.tuia.cn/apollo/getIdMapStrByKeyStrResourceId',
      {
        keyStr: 'langPage.copy.black.app.ids',
        resourceId: a_appId
      },
      successCB,
      errorCB
    )
  },
  /**
   * 不那么精准的判断一下当前sdk是否为tuia域名
   */
  isTuia() {
    // const domainList = CONST.domainList
  }
}

module.exports = advert
