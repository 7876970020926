/**
 * 弃用代码保留
 */

const utils = require('./utils')
const CONST = require('./CONST')

const heartBeat = {
  /**
   * 业务数据上传封装 用于行为统计 & 上报特殊数据（不触发行为统计时）   数据上报入口二
   * 最终数据结构为{a:xx, b:xx, c: xx, d:xx, ...}
   * @param {Object} typeData 数据来自CONST.js 包含url与type
   * @param {Object} json 需要上传的数据
   */
  statistics(typeData, json) {
    if (typeData.type) json.type = typeData.type
    json._t = new Date().getTime()
    utils.ajax({
      url: typeData.url,
      data: json
    })
  },
  /**
   * 触发心跳埋点 && 点击行为监控 (此功能不再维护，保留功能即可，重构代码上线之后会下线此功能)
   * @param {Object} json 转化参数
   */
  startHeartBreath(json) {
    if (document.getElementById('send_log')) {
      // 百奇自带心跳，当在百奇中时不使用SDK的心跳
      if (!!window.BQ) {
        return;
      }
      heartBeat.perIntervel = window.setInterval(heartBeat.countTime, CONST.HEARTBEAT_TIME)
      heartBeat.behaviorStatistics()
      heartBeat.visibilityListen()
    } else {
      // heartBeat.statistics(CONST.OHTER.nosendId, json)
    }
  },
  /**
   * 点击行为监控
   */
  behaviorStatistics() {
    let [startX, startY, endX, endY] = [0, 0, 0, 0]
    document.addEventListener('touchstart', (event) => {
      heartBeat.count++
      heartBeat.statistics(CONST.HEARTBEAT.normal, heartBeat.getHeartBreathData('touch'))
      var touch = event.targetTouches[0]
      startX = touch.pageX
      startY = touch.pageY
      var ev = event || window.event
      var target = ev.target || ev.srcElement
      if (target.nodeName.toLowerCase() === 'input') {
        heartBeat.statistics(CONST.HEARTBEAT.normal, heartBeat.getHeartBreathData('input'))
        heartBeat.count2++
      }
    })
    document.addEventListener('touchend', (event) => {
      endX = event.changedTouches[0].pageX
      endY = event.changedTouches[0].pageY
      var changeY = Math.abs(endY - startY)
      var changeX = Math.abs(endX - startX)
      if (endX !== 0 && endY !== 0) {
        if (changeX > 5 || changeY > 5) {
          heartBeat.statistics(CONST.HEARTBEAT.normal, heartBeat.getHeartBreathData('move'))
          heartBeat.count1++
          endX = 0
          endY = 0
        }
      }
    }, false)
  },
  /**
   * 页面隐藏再显示之后重置心跳统计
   */
  visibilityListen() {
    var state, visibilityChange
    if (typeof document.hidden !== 'undefined') {
      // hidden = 'hidden'
      visibilityChange = 'visibilitychange'
      state = 'visibilityState'
    } else if (typeof document.mozHidden !== 'undefined') {
      // hidden = 'mozHidden'
      visibilityChange = 'mozvisibilitychange'
      state = 'mozVisibilityState'
    } else if (typeof document.msHidden !== 'undefined') {
      // hidden = 'msHidden'
      visibilityChange = 'msvisibilitychange'
      state = 'msVisibilityState'
    } else if (typeof document.webkitHidden !== 'undefined') {
      // hidden = 'webkitHidden'
      visibilityChange = 'webkitvisibilitychange'
      state = 'webkitVisibilityState'
    }
    document.addEventListener(visibilityChange, () => {
      if (document[state] !== 'visible') {
        window.clearInterval(heartBeat.perIntervel)
      } else if (document.getElementById('send_log')) {
        window.clearInterval(heartBeat.perIntervel)
        heartBeat.sessionID = parseInt(Math.random() * 9999) + parseInt(new Date().getTime()).toString().substr(5, 8)
        heartBeat.count = 0
        heartBeat.count1 = 0
        heartBeat.count2 = 0
        heartBeat.timeCount = 0
        heartBeat.perIntervel = setInterval(heartBeat.countTime, CONST.HEARTBEAT_TIME)
      }
    }, false)
  },
  /**
   * 启动心跳埋点
   */
  countTime() {
    heartBeat.statistics(CONST.HEARTBEAT.normal, heartBeat.getHeartBreathData('perSec'))
    heartBeat.perCount++
    if (heartBeat.perCount === 300) window.clearInterval(heartBeat.perIntervel)
  },
  /**
   * 获取心跳买点 && 行为监控 数据
   * @param {String} action
   */
  getHeartBreathData(action) {
    return {
      sessionID: heartBeat.sessionID,
      touchNum: heartBeat.count,
      inputNum: heartBeat.count2,
      moveNum: heartBeat.count1,
      action
    }
  },
  sessionID: parseInt(Math.random() * 9999) + parseInt(new Date().getTime()).toString().substr(5, 8),
  perCount: 0,
  perIntervel: null,
  count: 0,
  count1: 0,
  count2: 0
}

module.exports = heartBeat
