// Require the polyfill before requiring any other modules.
require('intersection-observer');
const utils = require('./utils')
const advert = require('./advert')
const CONST = require('./CONST')
const heartBeat = require('./useless')
const { startDomMonitor, sendSubTypeLog } = require('./dom_monitor')
const { initDurationMonitor } = require('./duration_monitor')

/**
 * 版本号，订单号
 */
const version = '3.0.5' // 直接包含了 hunter 3.0.5
const oId = utils.getConfig('orderId') || advert.getPathParam('a_oId')
/**
 * 定义初始数据
 */
const json = {
  version,
  referrer: document.referrer,
  url: utils.getConfig('url') || window.location.href || window.top.location.href,
  cid: utils.getConfig('userId') || advert.getPathParam('a_cid'),
  tuiaId: utils.getConfig('orderId') || advert.getPathParam('a_tuiaId'),
  oId,
  landId: utils.getConfig('landId') || advert.getPathParam('id'),
  origin: window.landOrigin || ''
}

/**
 * 🎁 百奇特殊字段
 */
// 百奇商品页
if (window.location.href.indexOf('/item/detail2') !== -1 || window.location.href.indexOf('/item/detail3') !== -1) {
  json.qiho_item_id = advert.getPathParam('id')
}
// 百奇集合页
if (window.location.href.indexOf('/collection/index') !== -1) {
  json.collection_id = advert.getPathParam('collId')
}

/**
 * ✨☝️✨1.优先执行各类曝光代码 （增值曝光弃用已删除，已跟数据开发及流量线后端确认）
 */

/**
 * 执行曝光之前先做判断：如果上一次域名检测的结果为‘failed’，这次就清除上一次的缓存重新检测
 * 该逻辑是为了避免埋点全挂了缓存中写入了'failed'，开发修复完成之后仍然取'failed'字符串作为埋点域名
 */
const TATrackHost = window.localStorage && window.localStorage.getItem("TATrackHost");
if (TATrackHost === 'failed') {
  window.localStorage.removeItem("TATrackHost");
}

// 常规曝光，会去重
advert.dataReport({
  typeData: CONST.EXPOSURE.normal,
  json
})
// 不去重曝光
advert.dataReport({
  typeData: CONST.EXPOSURE.repeat,
  json
})
/**
 * ✨☝️✨2.1定义并抛出转化方法（文档上推荐的方式）
 */
// 定义转化方法
const countLog = window.countLog || { }
/**
 * log.js先发布 对ready方法作兼容处理
 */
if (!countLog.ready) {
  countLog.ready = function(fn) {
    fn && fn()
  }
}
/**
 * 页面转化 （参数顺序遵从老版本，不可替换）
 * @param {Function} cb 转化成功/超时/失败 都会调用此方法
 * @param {Object} extraData 上传额外数据
 * @param {String} isActive 极少使用，是否为有效转化
 */
countLog.init = function(cb = utils.noop, extraData, isActive = 'open') {
  // 处理额外参数
  const temp = advert.extraDataHandler(extraData)
  const conversionData = utils.extendObj(json, temp)

  // 复制a_oId到剪贴板
  if (temp.isCopy) {
    // 校验黑名单是否可复制
    advert.isCoversionBlacklist(utils.getParam('a_appId'), function(data) {
      if (data !== 1) {
        utils.copyBoard(CONST.headString + oId)
      }
    })
  }

  reportCountLogInit(cb, conversionData, isActive)
}

function reportCountLogInit(cb = utils.noop, conversionData, isActive = 'open') {
  if (isActive !== 'close') {
    // isActie==='close'时为无效转化
    advert.dataReport({
      typeData: CONST.CONVERSION.normal,
      json: conversionData,
      successCB: cb,
      errorCB: cb,
      timeoutCB: cb
    })
  }
  advert.dataReport({
    typeData: CONST.CONVERSION.repeat,
    json: conversionData
  })
}

/**
 * 用于开发自定义埋点
 * @param {Function} cb 成功回调
 * @param {Object} extraData 上传额外数据
 */
countLog.sendClickData = function(cb = utils.noop, extraData) {
  const customData = utils.extendObj(extraData, json)
  advert.dataReport({
    typeData: CONST.CUSTOM.normal,
    json: customData,
    successCB: cb,
    errorCB: cb,
    timeoutCB: cb
  })
}

/**
 * http://cf.dui88.com/pages/viewpage.action?pageId=85837260
 * JS2.0功能升级，提供给外部广告主使用的api
 * @param {String} advertKey - 广告主的advertKey
 * @param {Number} threshold - 触发曝光的阈值，默认0.1，即区域10%的部分曝光就认定为曝光
 */
countLog.initAdvertKey = function (advertKey = "", threshold = 0.1) {
  if (!advertKey) {
    console.error("initAdvertKey失败，未传入advetKey");
    return;
  }
  countLog.advertKey = advertKey;
  countLog.threshold = threshold;
  /**
   * JS2.0升级，启动区域曝光的监听
   */
  setTimeout(() => {
    startDomMonitor()
  }, 50);
};

/**
 * JS2.0功能升级，直接打后端subType接口
 * @param {Number} subType - 必填，后端转化接口的subType字段
 * @param {String} advertKey - 非必填，不传默认从countLog.advertKey中取
 * @param {String} a_oId - 非必填，不传默认从url中取
 */
countLog.sendSubTypeLog = sendSubTypeLog;

// 抛出转化方法
// window.top是为了适用于部分iframe场景 （向上不跨域，向下跨域）
window.countLog = countLog
// 在iframe中直接使用top可能存在跨域问题
try {
  window.top.countLog = countLog
} catch(e) {}
// 不允许更改window下的countLog方法的引用与其内容的引用
try {
  // if (advert.isTuia()) {
  // 🙅‍♀️🙅‍♀️🙅‍♀️ 问题待解决
  Object.defineProperties(countLog, {
    init: {
      writable: false
    },
    sendClickData: {
      writable: false
    }
  })
  Object.defineProperty(window, 'countLog', {
    writable: false
  })
  // }
} catch (e) { }
/**
 * ✨☝️✨2.2绑定转化事件（文档中已经删除，功能保留）
 */
advert.countLogEventBind(countLog)
/**
 * ✨☝️✨3.启动行为统计埋点
 */
// heartBeat.startHeartBreath(json)
/**
 * ✨☝️✨4.存储有效数据到localStorage
 */
advert.setCacheData(json)
/**
 * ✨☝️✨5.引入狩猎
 */
require('./hunter')
/**
 * 🌕 🌖 🌗 🌘 🌑 开启页面停留时长监控
 */
initDurationMonitor()
