const utils = require("./utils");
const advert = require("./advert");

let oId = utils.getConfig("orderId") || advert.getPathParam("a_oId");
oId = oId == -1 ? "" : oId;

/**
 * JS2.0功能升级，直接打后端转化接口
 * @param {Number} subType - 必填，后端转化接口的subType字段
 * @param {String} advertKey - 非必填，不传默认从countLog.advertKey中取
 * @param {String} a_oId - 非必填，不传默认从url中取
 * @param {Object} extend - 非必填，由于之前传参设计问题只能在该位做拓展字段
 * @param {Boolean} extend.ignoreAdvertKey - 不校验advertKey
 * @param {Number} extend.LandingSingle - 目前仅在subType=19时使用，判断要不要走去重逻辑
 */
const sendSubTypeLog = function(subType, advertKey, a_oId, extend = {}) {
  const validLogData = function(data, errorTip) {
    if (!data) {
      console.error(errorTip);
      return false;
    }
    return true;
  };
  let validList = [
    {
      data: subType,
      errorTip: "sendSubTypeLog失败，未传入subType"
    },
    {
      data: a_oId || oId,
      errorTip: "sendSubTypeLog失败，未传入a_oId"
    }
  ];
  // 处理ignoreAdvertKey
  if (!extend.ignoreAdvertKey) {
    validList.push({
      data: advertKey || window.countLog.advertKey,
      errorTip: "sendSubTypeLog失败，未传入advertKey"
    });
  }
  let validResult = true;
  for (let item of validList) {
    if (!validLogData(item.data, item.errorTip)) {
      validResult = false;
      break;
    }
  }
  if (!validResult) {
    return;
  }
  // 删除extend.ignoreAdvertKey
  delete extend.ignoreAdvertKey;
  utils.ajax({
    // TODO：注意此处提交代码时应为生产环境的地址
    url: "https://activity.tuia.cn/log/effect/v2",
    data: Object.assign(
      {},
      {
        subType,
        advertKey: advertKey || countLog.advertKey || "",
        a_oId: a_oId || oId,
        frontEndIdentity: Math.random()
          .toString(32)
          .slice(2, 8)
      },
      extend
    )
  });
};

// 获取曝光元素的属性值
const getExposureAttribute = element => {
  return {
    triggered: parseInt(element.getAttribute("TA-exposure-triggered")),
    exposureSubType: parseInt(element.getAttribute("TA-exposure") || 0),
    exposureDelaySubType: parseInt(
      element.getAttribute("TA-exposure-delay") || 0
    ),
    exposureId: element.getAttribute("TA-exposure-id")
  };
};

// 解除元素的监听
const checkObserveElement = (element, observer, allExposureElementCount) => {
  observer.unobserve(element);
  allExposureElementCount--;
  if (allExposureElementCount === 0) {
    observer.disconnect();
  }
};

/**
 * dom元素的埋点监控
 * 支持dom区域曝光和3s曝光，埋点发送到后端v2接口
 * 区域曝光：在元素中增加TA-exposure属性，属性对应的值为打的后端埋点subType
 *      如：<div TA-exposure="subType"></div>
 * 3s曝光：在元素中增加TA-exposure-delay属性，属性对应的值为打的后端埋点subType
 *      如：<div TA-exposure-delay="subType"></div>
 */
const startDomMonitor = () => {
  console.log("监听区域元素曝光");
  // 获取页面中加了曝光和3s曝光的元素
  const exposureElements = document.querySelectorAll("[TA-exposure]");
  const delayExposureElements = document.querySelectorAll(
    "[TA-exposure-delay]"
  );
  // 维护一个对象用于记录需要3s曝光的元素的曝光情况
  let exposureDelayElementStatusMap = {};
  // 维护计时器对象
  let exposureDelayElementTimerMap = {};
  // 需要监听的元素计数器，当为0时说明全部元素已触发可以清除监听器
  let allExposureElementCount =
    exposureElements.length + delayExposureElements.length;
  // intersection observer触发的阈值
  const options = {
    threshold: window.countLog.threshold || 0.1
  };
  // intersection observer触发的回调
  const intersectionCallback = entries => {
    entries.forEach(item => {
      const element = item.target;
      const {
        triggered,
        exposureSubType,
        exposureDelaySubType,
        exposureId
      } = getExposureAttribute(element);
      // 更新当前元素的曝光状态
      exposureDelayElementStatusMap[`${exposureId}`] = item.isIntersecting;
      // 如果是从视窗中消失或触发过曝光直接返回
      if (!item.isIntersecting || triggered) {
        return;
      }
      // 如果定义了曝光属性则发送曝光
      if (exposureSubType && exposureSubType > 0) {
        sendSubTypeLog(exposureSubType);
        checkObserveElement(element, observer, allExposureElementCount);
        element.setAttribute("TA-exposure-triggered", "1");
      }
      // 如果定义了三秒曝光则添加定时器三秒后检测曝光状态
      if (exposureDelaySubType && exposureDelaySubType > 0) {
        // 如果挂载了定时器就走原来定时器的逻辑
        if (exposureDelayElementTimerMap[`${exposureId}`]) {
          return;
        }
        exposureDelayElementTimerMap[`${exposureId}`] = setTimeout(() => {
          if (exposureDelayElementStatusMap[`${exposureId}`]) {
            sendSubTypeLog(exposureDelaySubType);
            checkObserveElement(element, observer, allExposureElementCount);
            element.setAttribute("TA-exposure-triggered", "1");
          }
          clearTimeout(exposureDelayElementTimerMap[`${exposureId}`]);
          delete exposureDelayElementTimerMap[`${exposureId}`];
        }, 3000);
      }
    });
  };
  // 创建一个 intersection observer
  const observer = new IntersectionObserver(intersectionCallback, options);
  // 用于给曝光/3s曝光元素添加唯一标识的计数器
  let exposureElementCount = 1;
  const setTriggerAndObserver = element => {
    const exposureId = `exposure${exposureElementCount}`;
    element.setAttribute("TA-exposure-id", exposureId);
    exposureDelayElementStatusMap[`${exposureId}`] = false;
    exposureElementCount++;
    element.setAttribute("ta-exposure-triggered", "0");
    observer.observe(element);
  };
  // 为需要曝光的元素加上触发标识并挂载监听
  exposureElements.forEach(element => {
    setTriggerAndObserver(element);
  });
  delayExposureElements.forEach(element => {
    setTriggerAndObserver(element);
  });
};

module.exports = {
  startDomMonitor,
  sendSubTypeLog
};
