// 去重规则：五分钟之内同一订单号（a_oId）去重
var DOMAIN = 'https://activity5.ggerg.com'

/**
 * 2021-03-22 refactor：埋点增加域名被封时的降级方案
 * 配置项中不直接传入埋点地址，而是传入域名和接口地址；原配置项还是保留不做维护
 * 由请求方法中进行域名的检测、替换和埋点地址的拼接
 */
module.exports = {
  /**
   * 曝光相关 其中repeat不去重
   */
  EXPOSURE: {
    normal: {
      type: 7,
      url: DOMAIN + '/log/inner',
      domain: DOMAIN,
      api: '/log/inner'
    },
    repeat: {
      type: 49,
      url: DOMAIN + '/log/landLog',
      domain: DOMAIN,
      api: '/log/landLog'
    }
  },
  /**
   * 转化相关 其中repeat不去重
   */
  CONVERSION: {
    normal: {
      type: 8,
      url: DOMAIN + '/log/inner',
      domain: DOMAIN,
      api: '/log/inner'
    },
    repeat: {
      type: 50,
      url: DOMAIN + '/log/landLog',
      domain: DOMAIN,
      api: '/log/landLog'
    }
  },
  /**
   * 自定义埋点 可用于落地页额外数据埋点 不去重
   */
  CUSTOM: {
    normal: {
      type: 110,
      url: DOMAIN + '/log/landLog',
      domain: DOMAIN,
      api: '/log/landLog'
    }
  },
  /**
   * 心跳行为埋点 用于用户行为监控
   */
  HEARTBEAT: {
    normal: {
      url: DOMAIN + '/statistics/stopPageCount',
      domain: DOMAIN,
      api: '/statistics/stopPageCount'
    }
  },
  /**
   * 无推啊ID时上报信息 废弃
   * 无心跳埋点时上报信息
   * 2021-03-22 refactor：废弃配置项，不做维护
   */
  OHTER: {
    notuiaId: {
      url: DOMAIN + '/log/noTuiaId/inner'
    },
    nosendId: {
      type: 'nosend',
      url: '/statistics/activityPagePerf'
    }
  },
  /**
   * 长按时间 ms
   */
  PRESSTIME: 500,
  /**
   * 行为数据统计间隔 ms
   */
  HEARTBEAT_TIME: 1000,
  /**
   * 存储前缀
   */
  CACHE_NAME: '_coll_',
  /**
   * countLog.init 复制订单号前缀
   */
  headString: 'tuia=',
  /**
   * 推啊域名数组
   */
  domainList: ['tuiasnake', 'dui88'],
  /**
   * 2021-03-22 refactor：增加域名被封时的降级备用域名
   */
  backupHosts: ['https://activity.tuiaaaa.com', 'https://activity.tuiaaaa.cn'],
  defaultHost: DOMAIN
}
